<template lang='pug'>
	.wizard-wrap-xl.container-xs-24.mx-sm-auto.mb-5.lh-18.terms
		h1.mb-6 {{ $t('subterms.title') }}
		p.text-muted.mb-4 {{ $t('date.published') }}: {{ updatedDate }}

		p.mb-4(v-html="$t('subterms.desc')")
		hr.primary.my-6

		h2.mb-6 1. {{ $t('subterms.common[0].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '1'")
			li(v-for='i in ul0' :key='i.title')
				span
					b {{ i.title }}&nbsp;
					span(v-html='i.text')
		p.mb-4 {{ $t('subterms.common[0].others') }}

		hr.primary.my-6

		h2.mb-6 2. {{ $t('subterms.common[1].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '2'")
			li(v-for='i in common[1].ul' :key='i.text') {{ i.text }}

		hr.primary.my-6

		h2.mb-6 3. {{ $t('subterms.common[2].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '3'")
			li(v-for='i in common[2].ul' :key='i.text')
				div(v-if='i.ulDeep')
					span(v-html='i.text')
					ol.ol-deep.mt-2(style="--n: '3'" v-if='i.ulDeep')
						li(v-for='d in i.ulDeep' :key='d.text')
							div(v-html='d.text')
				span(v-else v-html='i.text')

		hr.primary.my-6

		h2.mb-6 4. {{ $t('subterms.common[3].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '4'")
			li(v-for='i in common[3].ul' :key='i.text')
				div(v-html='i.text')

		hr.primary.my-6

		h2.mb-6 5. {{ $t('subterms.common[4].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '5'")
			li(v-for='i in common[4].ul' :key='i.text' v-html='i.text')

		hr.primary.my-6

		h2.mb-6 6. {{ $t('subterms.common[5].title') }}
		ol.ol-deep.ol-deep-parent.mb-4(style="--n: '6'")
			li(v-for='i in common[5].ul' :key='i.text' v-html='i.text')

		h2.mb-6 7. {{ $t('subterms.common[6].title') }}
		ol.ol-deep.ol-deep-parent(style="--n: '7'")
			li
				div
					| {{ $t('subterms.common[6].desc') }}
					ol.ol-deep(style="--n: '7'")
						li(v-for='i in common[6].ul' :key='i.text' v-html='i.text')
		p
			b {{ $t('forms.requisites') }}:
		company-data
		br
		br
		pw-btn-up
</template>

<script>
import CompanyData from '@/components/Common/Company'
import PwBtnUp from '@/components/Buttons/PwBtnUp'
import moment from 'moment'
import {bus} from '@/main'

export default {
	metaInfo () { return {title: bus.$t('h1.serviceAgree')}},
	components: {
		CompanyData,
		PwBtnUp
	},
	data() {
		let date = '2022-06-21';
		let day = moment(date).format('DD');

		return {
			updatedDate	: moment(date).format('DD MMMM YYYY').replace(day, `«${day}»`),
		}
	},
	computed: {
		common () {
			let data = _.cloneDeep(bus.$t('subterms.common'));

			_.each(data, (m, i) => {
				_.each(data[i].ul, (u, iu) => {
					data[i].ul[iu].text = data[i].ul[iu].text
						.replace(/\$\{bus\.botPayName\}/g, bus.botPayName)
						.replace(/\$\{bus\.botPay\}/, bus.botPay)
						.replace(/\$\{bus\.botSupportName\}/g, bus.botSupportName)
						.replace(/\$\{bus\.botSupport\}/g, bus.botSupport)
						.replace(/\$\{bus\.botLink\}/g, bus.botLink)
						.replace(/\$\{bus\.botPay\}/g, bus.botPay);

					_.each(data[i].ul[iu].ulDeep, (d, id) => {
						if ( data[i].ul[iu].ulDeep[id].text )
							data[i].ul[iu].ulDeep[id].text = data[i].ul[iu].ulDeep[id].text
								.replace(/\$\{bus\.botPayName\}/g, bus.botPayName)
								.replace(/\$\{bus\.botPay\}/, bus.botPay)
								.replace(/\$\{bus\.botSupportName\}/g, bus.botSupportName)
								.replace(/\$\{bus\.botSupport\}/g, bus.botSupport)
								.replace(/\$\{bus\.botLink\}/g, bus.botLink)
								.replace(/\$\{bus\.botPay\}/g, bus.botPay);
					})
				})
			})

			return data;
		},
		ul0 () {
			let ul0 = _.cloneDeep(bus.$t('subterms.common[0].ul'));

			_.each(ul0, (m, i) => {
				ul0[i].text = ul0[i].text
					.replace(/\$\{bus\.botPayName\}/g, bus.botPayName)
					.replace(/\$\{bus\.botSupportName\}/g, bus.botSupportName)
					.replace(/\$\{bus\.botPay\}/, bus.botPay)
					.replace(/\$\{bus\.botSupport\}/, bus.botSupport)
					.replace(/\$\{bus\.botLink\}/, bus.botLink);
			})

			return ul0;
		}
	},
	created () {
		window.scrollTo(0,0);

		this.viewGeoIpRussia();
	}
}
</script>

<style lang="scss" scoped>
.terms {
	margin-top: 76px;

	@media(max-width: 767px) {
		margin-top: 20px;
	}
}
</style>
